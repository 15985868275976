<template>
  <div class="mypaginations">
    <div class="block">
      <el-pagination
        v-if="pagSize.total > 0"
        background
        :page-sizes="pagSize.page_sizes"
        :page-size="pagSize.page_size"
        :layout="pagSize.layout"
        :total="pagSize.total"
        :current-page.sync="pagSize.page_index"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pagSize: {
      type: Object,
    },
    allData: {
      type: Array,
    },
  },
  data() {
    return {
      paginationData: [], //分页数据
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.$emit("setPage", page);
      console.log("page22222", page);
      // 当前页
      let sortnum = this.pagSize.page_size * (page - 1);
      let table = this.allData.filter((item, index) => {
        return index >= sortnum;
      });
      // 设置默认分页数据
      this.paginationData = table.filter((item, index) => {
        return index < this.pagSize.page_size;
      });
      this.$emit("setHandleCurrent", this.paginationData);
    },
    handleSizeChange(page_size) {
      this.$emit("setSize", page_size);
      // 切换size
      this.pagSize.page_index = 1;
      this.pagSize.page_size = page_size;
      this.paginationData = this.allData.filter((item, index) => {
        return index < page_size;
      });
      this.$emit("setHandleSize", this.paginationData);
    },
  },
};
</script>

<style scoped="scoped">
@import "mypaginations.css";
.el-pagination {
  text-align: center;
  margin-top: 20px;
}
</style>